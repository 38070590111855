import React, { useRef, useState, useEffect } from 'react';
import { Layout, Menu, message, Card, Col } from 'antd';
import { Swiper, SwiperSlide } from "swiper/react";
import { CaretRightOutlined, } from '@ant-design/icons';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import "swiper/swiper-bundle.css";

import { Form, Input, Button, Row } from 'antd';
import axios from 'axios';
const { Header, Content } = Layout;
const TabContent = ({ title, images, currentIndex, setCurrentIndex, prevImage, nextImage }) => (
    <div>
        <h2 style={{ textAlign: 'center', margin: '20px 0' }}>{title}</h2>
        <div style={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    transition: 'transform 0.5s ease',
                    transform: `translateX(-${currentIndex * (100 / 3)}%)`,
                    width: `${(images.length / 3) * 100}%`,
                }}
            >
                {images.map((src, index) => (
                    <div key={index} style={{ flex: '0 0 33.33%', padding: '10px' }}>
                        <img
                            src={src}
                            alt={`House ${index + 1}`}
                            style={{ width: '100%', height: '550px' }}
                        />
                    </div>
                ))}
            </div>
            <button
                onClick={prevImage}
                style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}
            >
                <LeftOutlined />
                {/* <Icon type="left" /> */}
            </button>
            <button
                onClick={nextImage}
                style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
            >
                <RightOutlined />
            </button>
        </div>
    </div>
);


const Login = () => {
    const [form] = Form.useForm();
    const servicesRef = useRef(null);
    const packagesRef = useRef(null);
    const aboutRef = useRef(null);
    const homeRef = useRef(null);
    const contactRef = useRef(null);
  
    const scrollToServices = () => {
        if (servicesRef.current) {
            servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollToPackages = () => {
        if (packagesRef.current) {
            packagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollToaboutRef = () => {
        if (aboutRef.current) {
            aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollTohomeRef = () => {
        if (homeRef.current) {
            homeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const scrollTocontactRef = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    const images = [
        "/image1.jpeg",
        "/image2.jpeg",
        "/image3.jpeg",
        "/image4.jpeg",
        "/image1.jpeg",
        "/image2.jpeg",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp"
    ];
    const interiorimages = [
        "/common1.jpg",
        "/common2.jpg",
        "/common3.jpg",
        "/common4.jpg",
        "/common5.jpg",
        "/commmon6.jpg",
        "/commmon7.jpg",
        "/common9.jpg",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp",
        // "/house3.webp"
    ];
    const kidinteriorimages = [
        "/kid1.jpg",
        "/kid2.jpg",
        "/kid3.jpg",
        "/kid4.jpg",
        "/kid5.jpg",
        "/kid6.jpg",
        "/kid7.jpg",
        "/kid8.jpg",

    ];
    const masterinteriorimages = [
        "/master1.jpg",
        "/master2.jpg",
        "/master3.jpg",
        "/master4.jpg",
        "/master5.jpg",
        "/master6.jpg",


    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState('Master Bedroom');
    const imagesPerView = 3;
    useEffect(() => {
        // Check if the screen width is less than 500px
        const handleResize = () => {
            if (window.innerWidth < 500) {
                setCurrentIndex(0); // Reset to the first image on small screens
            }
        };

        // Initial check and add event listener
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // const [imagesPerView, setImagesPerView] = useState(3);
    // useEffect(() => {
    //     // Update imagesPerView based on screen size
    //     const updateImagesPerView = () => {
    //         setImagesPerView(window.innerWidth < 500 ? 1 : 3);
    //     };

    //     // Initial check
    //     updateImagesPerView();

    //     // Add event listener to handle window resize
    //     window.addEventListener('resize', updateImagesPerView);
    //     return () => window.removeEventListener('resize', updateImagesPerView);
    // }, []);
    const nextImage = (currentIndex, setCurrentIndex, imagesLength) => {
        if (currentIndex < imagesLength - imagesPerView) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevImage = (currentIndex, setCurrentIndex) => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };
    const onfinish = async (values) => {
        console.log(values)
        const body = {
            email: values.email,
            name: values.firstName,
            contact: values.contactNumber,
            pageLocation: "home",
        };
        try {
            // Assuming your API endpoint is as follows
            const response = await axios.post('http://192.168.0.104:4001/api/addclientdetails', body);
            console.log('Success:', response.data);
            form.resetFields();
            message.success(response.data.msg)
            // Handle success (e.g., show a success message)
        } catch (error) {
            console.error('Error:', error);
            message.error("error found")
            form.resetFields();
            // Handle error (e.g., show an error message)
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

    const [visibleImages, setVisibleImages] = useState(1); // Start with 1 for smaller screens

    const updateVisibleImages = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setVisibleImages(4);
        } else if (width < 1024) {
            setVisibleImages(4);
        } else {
            setVisibleImages(3);
        }
    };

    useEffect(() => {
        updateVisibleImages();
        window.addEventListener('resize', updateVisibleImages);

        return () => window.removeEventListener('resize', updateVisibleImages);
    }, []);



    return (
        // <div>login page</div>

        <>
            <Layout style={{ overflowX: "hidden" }}>
                <Header className="custom-header">
                    <div className="logo-container">
                        <img src="logo.webp" alt="Logo" className="logo" style={{ height: "45px", width: "100%" }} />
                    </div>
                    <div className="menu-container">
                        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                            <Menu.Item key="1" onClick={scrollTohomeRef}>Home</Menu.Item>
                            <Menu.Item key="2" onClick={scrollToaboutRef}>About</Menu.Item>
                            <Menu.Item key="3" onClick={scrollToPackages}>Package</Menu.Item>
                            <Menu.Item key="4" onClick={scrollToServices}>Services</Menu.Item>
                            <Menu.Item key="5" onClick={scrollTocontactRef}>Contact</Menu.Item>
                        </Menu>
                    </div>
                </Header>
                <Content ref={homeRef} style={{ height: "auto", backgroundColor: "white" }}>
                    <div style={{ fontSize: "62px", fontWeight: "bold", textAlign: "center", fontFamily: "initial" }}>Urban Plan</div>
                    <div style={{ textAlign: "center", fontSize: "17px" }}>Crafting Your Visions Into Reality</div>
                    {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <img
                            src="/house5.jpg"
                            alt="Urban Planning"
                            style={{ minWidth: "100%", height: "800px", }}
                        />
                    </div> */}
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}> */}
                    {/* Image Section */}
                    <Row  gutter={[16, 16]}style={{ marginTop: "20px" }}>
                    <Col xs={24} sm={24} md={11} lg={14} xl={11} offset={1}>
                            <img
                                src="/house5.jpg"
                                alt="Urban Planning"
                                style={{ height: '500px' }}
                            />
                        </Col>

                        <Col xs={24} sm={24} md={11} lg={4} xl={{span:4,offset:7}}   style={{ marginTop: "5px" }} ref={aboutRef}>
                            <Card>
                                <div style={{ textAlign: "center", width: "200px" }}>
                                    <div>
                                       
                                       
                                        <span style={{ fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>About Urban Plan</span>
                                       
                                        <p>
                                            Urban Plan is dedicated to providing high-quality construction services across the residential, commercial, and industrial sectors. Our mission is to realize our clients' visions through exceptional, tailored construction solutions. Our experienced team adheres to core values of quality, integrity, and innovation, ensuring that every project is executed with the highest level of professionalism and excellence.
                                        </p>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>


                    {/* </div> */}


                    <div className="heading-container">
                        <span className="main-p-head" style={{ fontSize: "18px" }}>
                            Welcome to Urban Plan, your choice for
                            construction and
                            renovation! Our experienced team is dedicated
                            to exceeding your expectations.
                        </span>
                    </div>

                    {/* <section className="home-p-section3" style={{ marginTop: "40px" }}>
                        <div className="section3-container" style={{ position: "relative" }}>
                            <div className="section3-image-container">
                                <img
                                    src="/new2.webp"
                                    alt="A beautiful house promoting special deals"
                                    className="section3-responsive-image"
                                />
                            </div>
                            <div className="section3-text-box" style={{
                                position: "relative", 
                                marginTop: "-10px", 
                                left: "10%",
                                transform: "translate(-50%, 0)", 
                                maxWidth: "700px",
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                                padding: "20px",
                                borderRadius: "8px",
                                // height:"300px"
                            }}>
                                <h4 className="main-p-head">Special Deals</h4>
                                <div className="main-p-text">
                                    <p>
                                        Add information about the sale and encourage users to
                                        take advantage of the promotion. Be sure to include any relevant details you want
                                        shoppers to know about the sale or your store.
                                    </p>
                                </div>
                                <div className="main-p-button">
                                    <button className="btn">
                                        Shop Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section className="home-p-section3" style={{ marginTop: "40px" }}>
                        <div className="section3-container" style={{ position: "relative" }}>
                            <div className="section3-image-container">
                                <img
                                    src="/new2.webp"
                                    alt="A beautiful house promoting special deals"
                                    className="section3-responsive-image"
                                />
                            </div>
                            <div className="section3-text-box" >
                                <h4 style={{marginTop:"50px"}} className="main-p-head">Special Deals</h4>
                                <div className="main-p-text">
                                    <p style={{ fontSize: "18px", width: "300px" }} >
                                        1 lakh Worth Furniture / 1 Lakh worth Electrical Lights FREE

                                    </p>

                                </div>
                                <div className="main-p-button">

                                    <button  onClick={scrollTocontactRef} className="btn" style={{ marginTop: "15px" }}>Get Detailed Specification</button>
                                </div>
                                <div style={{marginTop:"100px"}}> <p style={{fontSize:"10px"}}>Terms & Conditions applied</p></div>
                               
                            </div>
                            
                        </div>
                    </section>
                    <div>
                        {/* <div ref={packagesRef} style={{ textAlign: "center", marginTop: "40px", fontSize: "64px", color: "#78244d" }}>
                            <span>Packages</span>
                        </div> */}
                        <div ref={packagesRef} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: "60px", textAlign: "center", color: "#C2274F" }}>Packages</span>
                        </div>
                        <div className="table-layout">
                            <table style={{ width: "100%", marginTop: "20px", borderCollapse: "collapse" }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2", textAlign: "center" }}>Elite Package</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2", textAlign: "center" }}>Standard Package</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2", textAlign: "center" }}>Luxury Package</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2", textAlign: "center" }}>Royal Package</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", listStyle: "none" }}>
                                                <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>

                                                   Starts From: Rs.1350/sqft
                                                    <span style={{ marginBottom: "10px" }}>*</span>
                                                </div>
                                            </ul>
                                        </td>

                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", listStyle: "none" }}>
                                                <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>

                                                Starts From: Rs.1800/sqft
                                                    <span style={{ marginBottom: "10px" }}>*</span>
                                                </div>
                                            </ul>
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", listStyle: "none" }}>
                                                <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>

                                                Starts From:Rs.2000/sqft
                                                    <span style={{ marginBottom: "10px" }}>*</span>
                                                </div>
                                            </ul>
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", listStyle: "none" }}>
                                                <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>

                                                Starts From: Rs.2600/sqft
                                                    <span style={{ marginBottom: "10px" }}>*</span>
                                                </div>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>


                                                <div style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Structure Work
                                                </div>
                                                <div style={{ paddingLeft: "20px" }}>

                                                    <div>Architrctural Plan</div>
                                                    <div>3D Elevation</div>
                                                    <div>Masonry Work</div>
                                                    <div>TMT Steel</div>
                                                    <div>Birla Cement</div>
                                                    <div>Manufacture Sand</div>
                                                </div>
                                            </ul>
                                        </td>


                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Structure Work
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Architrctural Plan</div>
                                                    <div>3D Elevation</div>
                                                    <div>Masonry Work</div>
                                                    <div>TMT Steel</div>
                                                    <div>Birla Cement</div>
                                                    <div>Manufacture Sand</div>
                                                </div>
                                            </ul>
                                        </td>

                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Structure Work
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Architrctural Plan</div>
                                                    <div>3D Elevation</div>
                                                    <div>Structural Plan</div>
                                                    <div>Masonry Work</div>
                                                    <div>TMT Steel</div>
                                                    <div>Birla Cement</div>
                                                    <div>Manufacture Sand</div>
                                                </div>
                                            </ul>
                                        </td>


                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Structure Work
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Architrctural Plan</div>
                                                    <div>3D Elevation</div>
                                                    <div>Structural Drawings</div>
                                                    <div>Masonry Work</div>
                                                    <div>Indus TMT Steel</div>
                                                    <div>Birla Cement</div>
                                                    <div>Manufacture Sand</div>
                                                </div>
                                            </ul>
                                        </td>



                                    </tr>

                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Electrical Conduit</div>
                                                    <div>Switch Box</div>

                                                </div>
                                            </ul>
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>GM Swtiches</div>
                                                    <div>Anchor/Finolex Wires</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Anchor Roma Switches</div>
                                                    <div>Anchor/Finolex Wires</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>LeGrand Switches</div>
                                                    <div>Anchor/Finolex Wires</div>

                                                </div>
                                            </ul>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Grill Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>MS Gate</div>

                                                </div>
                                                <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                            </ul>
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Grill Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>MS Railings</div>
                                                    <div>MS Gate</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Grill Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>MS Railings</div>
                                                    <div>MS Gate</div>
                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Grill Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>SS Railings</div>
                                                    <div>Premium Gate</div>

                                                </div>
                                            </ul>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            {/* <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                   Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Scheme drawing</div>
                                                    <div>Elevation design</div>
                                                    <div>Half Layout: All Floor(3D)</div>
                                                    <div>Electrical Drawing: All Floor(2D)</div>
                                                    <div>Plumbing Drawing: All Floor(2D)</div>
                                                    <div>Working Drawing: All Floor(2D)</div>
                                                </div>
                                            </ul> */}
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Doors And Shutters
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Hone Main Door and Pooja Room</div>
                                                    <div>UPVC Windows</div>
                                                    <div>Flush Door for Bedroom and Bathroom</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Doors And Shutters
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Teak Main Doors and Pooja Room</div>
                                                    <div>Red Sal and Hone Shutters Windows</div>
                                                    <div>Designer Flush Doors</div>
                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Doors And Shutters
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Premium Teak Main Door and Pooja Room</div>
                                                    <div>Premium UPVC Windows/Hone Shutters</div>
                                                    <div>Hone Doors</div>

                                                </div>
                                            </ul>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            {/* <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                   Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Scheme drawing</div>
                                                    <div>Elevation design</div>
                                                    <div>Half Layout: All Floor(3D)</div>
                                                    <div>Electrical Drawing: All Floor(2D)</div>
                                                    <div>Plumbing Drawing: All Floor(2D)</div>
                                                    <div>Working Drawing: All Floor(2D)</div>
                                                </div>
                                            </ul> */}
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Floor Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>20mm Granite for Common area</div>
                                                    <div>Vetrified Tiles for Flooring</div>
                                                    <div>Anit Skid Tiles for Bathroom</div>
                                                    <div>Glazed Tiles for Kitchen and Pooja Room</div>
                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Floor Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>20mm Granite</div>
                                                    <div>Anti Skid Tiles for Bathroom</div>
                                                    <div>Glazed Tiles for Kitchen and Pooja Room</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Floor Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>20mm Marble</div>
                                                    <div>20mm Granite</div>
                                                    <div>Premium Tiles for Bathroom</div>
                                                    <div>Premium Tiles for Kitchen and Pooja Room</div>

                                                </div>
                                            </ul>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            {/* <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                   Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Scheme drawing</div>
                                                    <div>Elevation design</div>
                                                    <div>Half Layout: All Floor(3D)</div>
                                                    <div>Electrical Drawing: All Floor(2D)</div>
                                                    <div>Plumbing Drawing: All Floor(2D)</div>
                                                    <div>Working Drawing: All Floor(2D)</div>
                                                </div>
                                            </ul> */}
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Bathroom Fixtures
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Hindware and Jaquar Fittings</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Bathroom Fixtures
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Jaquar Make</div>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Bathroom Fixtures
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Premium Jaquar Fittings</div>
                                                    <div>Diverter Mixture</div>
                                                    <div>Wall Mount Comode</div>

                                                </div>
                                            </ul>
                                        </td>


                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            {/* <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                   Electrical Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Scheme drawing</div>
                                                    <div>Elevation design</div>
                                                    <div>Half Layout: All Floor(3D)</div>
                                                    <div>Electrical Drawing: All Floor(2D)</div>
                                                    <div>Plumbing Drawing: All Floor(2D)</div>
                                                    <div>Working Drawing: All Floor(2D)</div>
                                                </div>
                                            </ul> */}
                                        </td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Paint Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Asian Paints</div>
                                                    <div>Internal Paint Tractor Emulsion</div>
                                                    <div>External Paint Ace</div>
                                                    <div>Enamel Paint for grills</div>
                                                    <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Paint Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Asian Paints</div>
                                                    <div>Internal Paint Premium Emulsion</div>
                                                    <div>External Paint Apex</div>
                                                    <div>Enamel paint for Grills</div>
                                                    <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>

                                                </div>
                                            </ul>
                                        </td> <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                                            <ul style={{ textAlign: "left", paddingLeft: "20px", marginLeft: "20px", listStyle: "none" }}>
                                                <li style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                    <CaretRightOutlined style={{ marginRight: "5px" }} />
                                                    Paint Works
                                                </li>
                                                <div style={{ marginTop: "5px" }}>
                                                    <div>Asian Paints</div>
                                                    <div>Internal Paint Royal Emulsion</div>
                                                    <div>External Paint Apex Ultima</div>
                                                    <div>Polish for Doors and Frames</div>
                                                    {/* <div className="main-p-button"> */}
                                                    <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                                    {/* </div> */}
                                                    {/* <button onClick={scrollTocontactRef} style={{marginTop:"15px", color:"red", backgroundColor:"#9F9DF6",borderBlockColor:"blue"}}> Get Detailed Specification</button> */}
                                                </div>
                                            </ul>
                                        </td>


                                    </tr>

                                </tbody>
                            </table>
                        </div>







                        <div className="card-layout">
                            <Row gutter={16} style={{ marginTop: "20px" }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                    <Card title="Elite Package" bordered={false}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "15px", fontWeight: "bold" }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px" }}>

                                                <span>Structure Work</span>
                                            </div>
                                            <div>Electrical Work</div>
                                            <div>Grill Works</div>
                                            <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                            
                                        </div>

                                    </Card>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                    <Card title="Standard Package" bordered={false}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "15px", fontWeight: "bold" }}>
                                           
                                            <div>Structure Work</div>
                                            <div>Electrical Work</div>
                                            <div>Grill Works</div>
                                            <div>Doors & Shutters</div>
                                            <div>Floor Works</div>
                                            <div>Bathroom Works</div>
                                            <div>paint Works</div>
                                            <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                        </div>

                                    </Card>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                    <Card title="Luxury Package" bordered={false}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "15px", fontWeight: "bold" }}>
                                        <div>Structure Work</div>
                                            <div>Electrical Work</div>
                                            <div>Grill Works</div>
                                            <div>Doors & Shutters</div>
                                            <div>Floor Works</div>
                                            <div>Bathroom Works</div>
                                            <div>paint Works</div>
                                            <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                        </div>

                                    </Card>

                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                    <Card title="Royal Package" bordered={false}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", fontSize: "15px", fontWeight: "bold" }}>
                                        <div>Structure Work</div>
                                            <div>Electrical Work</div>
                                            <div>Grill Works</div>
                                            <div>Doors & Shutters</div>
                                            <div>Floor Works</div>
                                            <div>Bathroom Works</div>
                                            <div>paint Works</div>
                                            <button onClick={scrollTocontactRef} style={{ marginTop: "15px" }} className="packagebtn">Get Detailed Specification</button>
                                        </div>

                                    </Card>

                                </Col>

                            </Row>
                        </div>

                    </div>
                    <section>
                        <div ref={servicesRef} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: "60px", textAlign: "center", color: "#C2274F" }}>Services</span>
                        </div>
                        <div style={{ textAlign: "center", marginTop: "40px", fontSize: '16px', }}>At Urban Plan, we offer a diverse range of construction services meticulously designed to address the unique requirements of our clients. Whether you are interested in constructing a new home, renovating an existing space, or developing a commercial property, our team of seasoned professionals is committed to assisting you throughout every stage of the project. Whether it's your dream home or a commercial space, we’re excited to bring your vision to life with exceptional craftsmanship! Explore our services to see how we can help realise your vision.</div>
                    </section>


                    {/* <div style={{ display: "flex", flexDirection: "column", marginTop: "40px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%", padding: "0" }}>
                            <div style={{ flex: "1 1 200px", maxWidth: "780px" }}>
                                <img
                                    src="/house3.webp"
                                    alt="Urban Planning"
                                    style={{ width: "100%", height: "auto" }} // Responsive image
                                />
                            </div>

                            <div style={{ flex: "1 1 300px", minWidth: "200px", maxWidth: "470px", marginRight: "100px" }}>
                                <div style={{ fontSize: "40px", fontFamily: "initial", fontWeight: "bold", textAlign: "center", marginBottom: "10px" }}>
                                    Residential
                                </div>
                                <div style={{ fontSize: "40px", fontFamily: "initial", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
                                    Construction
                                </div>
                                <ul style={{ padding: "0", listStyleType: "none" }}>
                                    <li style={{ color: "#78244d", marginTop: "10px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Custom Homes</span>
                                        <span style={{ fontSize: "17px" }}>
                                            : We specialize in building custom homes that reflect your personal style and meet your specific needs.
                                        </span>
                                    </li>
                                    <li style={{ color: "#78244d", marginTop: "20px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Home Additions</span>
                                        <span style={{ fontSize: "17px" }}>
                                            : We build custom additions that enhance your living space.
                                        </span>
                                    </li>
                                    <li style={{ color: "#78244d", marginTop: "20px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Renovations and Remodeling</span>
                                        <span style={{ fontSize: "17px" }}>
                                            : Our renovation services transform your existing space.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%", padding: "0", }}>
                            <div style={{ flex: "1 1 200px", minWidth: "200px", maxWidth: "400px", marginLeft: "150px" }}>
                                <div style={{ fontSize: "40px", fontFamily: "initial", fontWeight: "bold", textAlign: "center", marginBottom: "10px" }}>
                                    Residential
                                </div>
                                <div style={{ fontSize: "40px", fontFamily: "initial", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>
                                    Construction
                                </div>
                                <ul style={{ padding: "0", listStyleType: "none" }}>
                                    <li style={{ color: "#78244d", marginTop: "10px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Custom Homes</span>
                                        <span style={{ fontSize: "17px" }}>
                                            : We build homes tailored to your lifestyle and preferences.
                                        </span>
                                    </li>
                                    <li style={{ color: "#78244d", marginTop: "20px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Home Additions</span>
                                        <span style={{ fontSize: "17px" }}>
                                            : Our additions blend seamlessly with your existing home.
                                        </span>
                                    </li>
                                    <li style={{ color: "#78244d", marginTop: "20px" }}>
                                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Renovations and Remodeling</span>
                                        <span style={{ fontSize: "17px" }}>
                                            : Transforming spaces to reflect your taste.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div style={{ flex: "1 1 200px", minWidth: "200px", maxWidth: "727px", marginBottom: "10px" }}>
                                <img
                                    src="/house6.webp"
                                    alt="Urban Planning"
                                    style={{ width: "100%", height: "auto", }} // Responsive image
                                />
                            </div>
                        </div>
                    </div> */}
                    <section className="home-p-sections" style={{ marginTop: "40px" }}>
                        <div className="RC-div" >
                            <div className="RC-img-div">
                                <img
                                    src="/house3.webp"
                                    // src="/image1.jpeg"
                                    alt="Descriptive alt text"
                                    className="RC-img"
                                // style={{ maxHeight: "300px" }}
                                />
                            </div>
                            <div className="RC-txt-div">
                                <div className="main-p-heading1" style={{ padding: "0px", marginBottom: "-15px", textAlign: "center", fontSize: "22px" }}>
                                    <span style={{ fontSize: "32px", fontWeight: "bold" }}>
                                        Residential Construction
                                    </span>
                                </div>
                                <div style={{ marginTop: "20x", }}>
                                    <ul>
                                        <li style={{ padding: "5px", marginTop: "40px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Custom Homes:
                                            </span>
                                            <span> We specialise in building custom homes that reflect your personal style
                                                and meet your specific needs. From the initial design phase to the final
                                                finishing touches,
                                                we work closely with you to ensure every detail is perfect.</span>

                                        </li>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Home Additions:
                                            </span>
                                            <span> Expand your living space with our expert home addition services. Whether you need an extra bedroom,
                                                a larger kitchen, or a new living area, we provide seamless integration with your existing home.</span>

                                        </li>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Renovations and Re-modeling:
                                            </span>
                                            <span> Transform your current home
                                                with our renovation and re-modeling services. We offer everything from
                                                minor updates to complete overhauls,
                                                enhancing both the functionality and aesthetics of your space.</span>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="home-p-sections">
                        <div className="RC-div">
                            <div className="RC-txt-div" style={{ width: "95%" }}>
                                <div className="main-p-heading1" style={{ padding: "0px", marginBottom: "-15px", textAlign: "center", fontSize: "22px" }}>
                                    <h2>
                                        Commercial Construction
                                    </h2>
                                </div>
                                <div style={{ padding: "5px" }}>
                                    <ul>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Office Buildings:
                                            </span>
                                            <span> Our team designs and constructs modern office
                                                buildings that foster productivity and reflect your company’s brand. We handle everything
                                                from site selection to final construction, ensuring your new office meets all your needs.</span>

                                        </li>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Retail Spaces:
                                            </span>
                                            <span> We create attractive and functional retail spaces that
                                                enhance the shopping experience for your customers. Our services
                                                include design, construction, and interior fit-outs, tailored to your business requirements.</span>

                                        </li>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Industrial Facilities:
                                            </span>
                                            <span> From warehouses to manufacturing plants,
                                                we build industrial facilities that support your operations.
                                                Our team ensures that each project meets industry standards and is completed efficiently.</span>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="RC-img-div">
                                <img
                                    src="/new.webp"
                                    //  src="/image2.jpeg"
                                    alt="Descriptive alt text"
                                    className="RC-img"
                                // style={{ maxHeight: "300px" }}
                                />
                            </div>
                        </div>
                    </section>

                    <section className="home-p-sections">
                        <div className="RC-div">
                            <div className="RC-img-div">
                                <img
                                    src="/interior.webp"
                                    // src="/image3.jpeg"
                                    alt="Descriptive alt text"
                                    className="RC-img"
                                // style={{ maxHeight: "300px" }}
                                />
                            </div>
                            <div className="RC-txt-div" style={{ width: "94%" }}>
                                <div className="main-p-heading1" style={{ padding: "0px", marginBottom: "-15px", textAlign: "center", fontSize: "22px" }}>
                                    <h2>
                                        Interior Design
                                    </h2>
                                </div>
                                <div style={{ width: "95%" }}>
                                    <ul>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Space Planning:
                                            </span>
                                            <span> Our interior design services include expert space
                                                planning to maximize the functionality and aesthetics of your interior spaces.
                                                We work with you to create layouts that enhance the flow and usability of each area.</span>

                                        </li>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Design Consultation:
                                            </span>
                                            <span> Collaborate with our skilled interior designers to
                                                bring your vision to life. We offer personalized design consultations
                                                to help you choose the right colors, materials, and finishes for your project.</span>

                                        </li>
                                        <li style={{ padding: "5px" }}>
                                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                                Renovations and Re-modeling:
                                            </span>
                                            <span> Transform your current home
                                                with our renovation and re-modeling services. We offer everything from
                                                minor updates to complete overhauls,
                                                enhancing both the functionality and aesthetics of your space.</span>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ fontSize: "60px", textAlign: "center", color: "#C2274F" }}>Interior Photos</span>
                        </div>
          
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                <div
                                    onClick={() => setSelectedTab('Master Bedroom')}
                                    style={{
                                        margin: '0 10px',
                                        borderBottom: selectedTab === 'Master Bedroom' ? '2px solid blue' : 'none',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Master Bedroom
                                </div>
                                <div
                                    onClick={() => setSelectedTab('Kid Bedroom')}
                                    style={{
                                        margin: '0 10px',
                                        borderBottom: selectedTab === 'Kid Bedroom' ? '2px solid blue' : 'none',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Kids Bedroom
                                </div>
                                <div
                                    onClick={() => setSelectedTab('Common Areas')}
                                    style={{
                                        margin: '0 10px',
                                        borderBottom: selectedTab === 'Common Areas' ? '2px solid blue' : 'none',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Common Areas
                                </div>
                            </div>
                            <div className="web-page"  >
                                {selectedTab === 'Master Bedroom' && (
                                    <TabContent
                                        // title="Master Bedroom"
                                        images={interiorimages}
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        prevImage={() => prevImage(currentIndex, setCurrentIndex)}
                                        nextImage={() => nextImage(currentIndex, setCurrentIndex, interiorimages.length)}
                                    />
                                )}
                                {selectedTab === 'Kid Bedroom' && (
                                    <TabContent
                                        // title="Kid Bedroom"
                                        images={kidinteriorimages}
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        prevImage={() => prevImage(currentIndex, setCurrentIndex)}
                                        nextImage={() => nextImage(currentIndex, setCurrentIndex, kidinteriorimages.length)}
                                    />
                                )}
                                {selectedTab === 'Common Areas' && (
                                    <TabContent
                                        // title="Common Areas"
                                        images={masterinteriorimages}
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        prevImage={() => prevImage(currentIndex, setCurrentIndex)}
                                        nextImage={() => nextImage(currentIndex, setCurrentIndex, masterinteriorimages.length)}
                                    />
                                )}
                            </div>
                            <div className="mobile-page"  >
                                {selectedTab === 'Master Bedroom' && (
                                      masterinteriorimages.map((src, index) => (
                                        <img
                                            key={index}
                                            src={src}
                                            alt={`House ${index + 1}`}
                                            style={{ width: '100%',  height: 'auto', marginBottom: '20px' }}
                                        />
                                    ))
                                )}
                                {selectedTab === 'Kid Bedroom' && (
                                   
                                        // title="Kid Bedroom"
                                        kidinteriorimages.map((src, index) => (
                                            <img
                                                key={index}
                                                src={src}
                                                alt={`House ${index + 1}`}
                                                style={{ width: '100%',  height: 'auto', marginBottom: '20px' }}
                                            />
                                        ))
                                  
                                )}
                                {selectedTab === 'Common Areas' && (
                                     interiorimages.map((src, index) => (
                                        <img
                                            key={index}
                                            src={src}
                                            alt={`House ${index + 1}`}
                                            style={{ width: '100%',  height: 'auto', marginBottom: '20px' }}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                
                    <div style={{ marginTop: "20px", position: "relative", textAlign: "center" }}>
                        <img
                            src="/peterzumthor.webp"
                            alt="Urban Planning"
                            className="responsive-image" // Use a class for better control
                            style={{ width: "100%", objectFit: "cover" }} // Responsive image
                        />
                        <div style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1,
                            color: "black", // Change text color for better visibility
                            padding: "0 10px", // Add padding for better readability
                            maxWidth: "90%", // Ensure it doesn't overflow the image
                            whiteSpace: "normal", // Allow text to wrap
                        }}>
                            <span style={{
                                fontWeight: "bold",
                                textDecoration: "underline",
                                margin: "0",
                                textAlign: "center",
                                fontSize: "24px",
                            }}>
                                Construction is the art of making a meaningful whole out of many parts. Buildings are witnesses to the human ability to construct concrete things.
                            </span>
                        </div>
                    </div>




                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <span style={{ fontSize: "64px", textAlign: "center", color: "#C2274F" }}>Our Projects</span>
                    </div>


                    <div style={{ marginTop: '20px' }}>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", padding: "20px" }}>
                                {images.map((src, index) => (
                                    <div key={index} style={{ flex: "1 1 30%", margin: "10px", overflow: "hidden", position: "relative" }}>
                                        <img
                                            src={src}
                                            alt={`House ${index + 1}`}
                                            style={{
                                                width: "100%",
                                                height: "500px",
                                                transition: "transform 0.9s ease",
                                            }}
                                            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                                            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* )} */}

                        {/* <div style={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
            {images.length === 0 ? (
                <p>No images available</p>
            ) : (
                <div style={{
                    display: 'flex',
                    transition: 'transform 0.5s ease',
                    transform: `translateX(-${currentIndex * (100 / visibleImages)}%)`,
                    width: `${(images.length * (100 / visibleImages))}%`,
                }}>
                    {images.map((src, index) => (
                        <div key={index} style={{ flex: `0 0 ${100 / visibleImages}%`, padding: '10px' }}>
                            <img
                                src={src}
                                alt={`Image ${index + 1}`}
                                style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'cover' }}
                            />
                        </div>
                    ))}
                </div>
            )}
            <button onClick={prevImage} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}>Back</button>
            <button onClick={nextImage} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>Next</button>
        </div> */}
                    </div>


                    {/* <div ref={contactRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}> */}
                       
                    {/* </div> */}

                </Content>
            </Layout>

        </>
    )
}
export default Login